<template>
  <div>
    <OsiguDrawer/>
      <v-main>
        <router-view></router-view>
      </v-main>
    <OsiguFooter/>
  </div>

</template>

<script>
import OsiguDrawer from "@/components/drawer/OsiguDrawer.vue";
import OsiguFooter from "@/components/footer/OsiguFooter.vue";
export default {
components: { 
    OsiguDrawer,
    OsiguFooter
  }
}
</script>